<template>
  <module-container :class="['wapNewBanner', device.orientation]">
    <div :class="['banner-container', classBase + 'banner']">
      <swiper
        v-if="list.length > 0"
        ref="mySwiper"
        :auto-destroy="true"
        :auto-update="true"
        :options="swiperOptions"
        class="swiper-box"
      >
        <template v-for="item in list">
          <swiper-slide :key="item.id" class="banner-item" v-if="!item.is_app">
            <a :href="item.jump_url || 'javascript:;'" :target="item.jump_url ? '_blank' : '_self'">
              <img :src="item.thumb" alt="" class="swiper-lazy" />
            </a>
          </swiper-slide>
        </template>
      </swiper>
      <div class="swiper-pagination"></div>
    </div>
  </module-container>
</template>

<script>
import ModuleContainer from "./moduleContainer";
import { directive, Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "bannerContainer",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  model: {},
  components: {
    Swiper,
    SwiperSlide,
    ModuleContainer
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: true,
        pagination: {
          el: ".swiper-pagination"
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  methods: {
    handleJumpUrl(item) {
      console.log(item);
      if (item.jump_url) {
        window.open(item.jump_url);
      }
    }
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.wapNewBanner {
  &.landscape {
    display: none;
  }
}
.banner-container {
  width: 100%;
  height: 326px;
  overflow: hidden;
  position: relative;

  .banner-item {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: #fff;
    }
  }
}

.wap-banner {
  height: 143px;
  margin-bottom: 0;
  &.landscape {
    height: 198px;
  }

  /deep/ .swiper-pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    justify-content: center;
    .swiper-pagination-bullet {
      width: auto;
      height: 3px;
      background: #c4c4c4;
      opacity: 1;
      border-radius: 0;
      margin-right: 2px;
      flex: 1;
    }

    .swiper-pagination-bullet-active {
      background: #6bc7ff;
    }
  }
  &.landscape {
    .banner-item {
      img {
        height: 195px;
      }
    }
  }

  .banner-item {
    img {
      height: 140px;
    }
  }
}

.pc-banner {
  /deep/ .swiper-pagination {
    position: absolute;
    bottom: 16px;
    right: 24px;
    z-index: 10;

    .swiper-pagination-bullet {
      width: 14px;
      height: 4px;
      background: #cccccc;
      border-radius: 3px;
      opacity: 1;
      margin: 0 2px;
    }

    .swiper-pagination-bullet-active {
      width: 24px;
      background: #ffb534;
    }
  }

  .banner-item {
    img {
      height: 326px;
    }
  }
}
</style>
