<template>
  <module-container
    :title="typeof title === 'string' ? title : ''"
    :isMore="typeof title === 'string' ? true : false"
    :url="'/games?type=' + activeType"
  >
    <template #icon>
      <img v-if="type === 'every_day'" src="../../../assets/images/calendar-icon.png" alt="" />
      <img v-if="type === 'new'" src="../../../assets/images/game-icon.png" alt="" />
    </template>
    <template v-if="Array.isArray(title)" #title>
      <span class="custom-title">
        <span
          class="title-item"
          v-for="(item, index) in title"
          :key="index"
          :class="{ active: titleType[activeType] === item }"
          @click="handleChangeType(index)"
        >
          {{ item }}
        </span>
      </span>
    </template>
    <template #title-right> </template>
    <div class="content">
      <div v-if="type === 'every_day'" :class="['game-list-container', device.orientation]">
        <div class="game-item" v-for="(game, index) in list" :key="index">
          <div class="swiper-container">
            <swiper
              :auto-destroy="true"
              :auto-update="true"
              :options="{
                pagination: {
                  el: '.custom-swiper-pagination-' + index,
                  clickable: true
                }
              }"
              class="swiper-box"
            >
              <swiper-slide v-for="(item, index) in game.screen_shot" :key="index" class="swiper-slide">
                <a :href="getJumpWikiUrl(game.alias, game.id, game.wiki_created_at != 0)" :title="game.name">
                  <img v-if="item.type === 'image'" :src="item.src" alt="" />
                </a>
                <div v-if="item.type === 'video'" class="video">
                  <i class="wiki-icon wiki-icon-video-play" @click="openVideoPlay(item.src)"></i>
                  <img :src="item.cover" alt="" />
                </div>
              </swiper-slide>
            </swiper>
            <div
              v-show="game.screen_shot.length > 1"
              class="custom-swiper-pagination"
              :class="['custom-swiper-pagination-' + index]"
            ></div>
          </div>
          <a
            :href="getJumpWikiUrl(game.alias, game.id, game.wiki_created_at != 0)"
            :title="game.name"
            target="_blank"
            class="game-box"
          >
            <div class="game-icon">
              <img :src="game.icon" :alt="game.name" />
            </div>
            <div class="game-info">
              <div class="game-name">
                <span class="name">{{ game.name }}</span>
                <span class="time"> 上线时间：{{ dayjs(game.pub_at * 1000).format("MM-D") }} </span>
              </div>
              <div class="game-desc">
                {{ game.description }}
              </div>
            </div>
          </a>
        </div>
      </div>
      <div v-else class="game-list-timeline" ref="gameListTimeline">
        <a
          v-for="(item, index) in list"
          :key="index"
          :href="getJumpWikiUrl(item.alias, item.id)"
          :title="item.name"
          target="_blank"
          class="item"
        >
          <div class="top-box">
            <div class="date">
              {{ item.pub_at | dateFormat("MM月DD日") }}
            </div>
            <div class="line">
              <span class="round"></span>
            </div>
          </div>
          <div class="img">
            <img v-lazy="handleImgSize(item.icon)" :alt="item.name" />
          </div>
          <div class="text-box">
            <div class="title">{{ item.name }}</div>
          </div>
        </a>
      </div>
      <!-- 视频播放 -->
      <el-dialog
        :visible.sync="videoPlayVisible"
        width="100%"
        append-to-body
        center
        @closed="closeVideoPlay"
        custom-class="video-player"
      >
        <template v-if="playVideo">
          <video width="100%" autoplay controls :src="playVideo"></video>
        </template>
      </el-dialog>
    </div>
  </module-container>
</template>

<script>
import dayjs from "dayjs";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// components
import ModuleContainer from "./moduleContainer";
// import SvgIcon from "../../../components/SvgIcon/svgIcon";
// plugins
import { getJumpWikiUrl, throttle } from "../../../plugins/util";
// apis
import { apiGameList } from "@/api/wikiApi";

const titleType = {
  new: "最近新游",
  future: "每日新游",
  foretell: "情报公开",
  follow: "特别期待",
  every_day: "每日新游"
};
export default {
  name: "newGameContainer",
  mixins: [],
  model: {},
  components: {
    Swiper,
    SwiperSlide,
    // SvgIcon,
    ModuleContainer
  },
  props: {
    type: {
      type: [String, Array],
      required: true
    },
    data: {
      type: [Object, Array],
      default: () => []
    }
  },
  data() {
    return {
      titleType,
      activeType: "",
      scrollLeft: 0,
      slideNum: 0,
      showNext: true,
      showPrev: false,
      videoPlayVisible: false,
      playVideo: "",
      timeLineItemWidth: 0,
      timeLineScrollLeft: 0,
      new_page_no: 1,
      new_page_total: 2,
      newGameList: [],
      future_page_no: 1,
      future_page_total: 2,
      futureGameList: [],
      isInitTimelineData: true
    };
  },
  computed: {
    title() {
      if (typeof this.type === "string") {
        return titleType[this.type];
      } else if (Array.isArray(this.type)) {
        return this.type.map(type => titleType[type]);
      }
      return [];
    },
    list() {
      if (this.activeType === "every_day") {
        const newList = this.data["new"] || [];
        return newList.slice(0, 3);
      }
      if (this.activeType === "new") {
        const futureList = this.data["future"] || [];
        const newList = this.data["new"] || [];
        const newGameList = [...this.newGameList];
        const futureGameList = [...this.futureGameList];
        const list = [
          ...[...[...futureGameList.slice(futureList.length)].reverse(), ...[...futureList].reverse()],
          ...[...newList, ...newGameList.slice(newList.length)]
        ];
        return list;
      }
      if (this.activeType) {
        return this.data[this.activeType] || [];
      }
      return [];
    },
    slideContainerWidth() {
      const num = this.list.length;
      return num * 535 + (num - 1) * 21;
    }
  },
  watch: {
    type: {
      handler(val) {
        if (typeof val === "string") {
          this.activeType = val;
        } else if (Array.isArray(val)) {
          this.activeType = val[0];
        }
      },
      immediate: true
    },
    list(val, oldVal) {
      const newNum = val.length - oldVal.length;
      if (newNum > 0 && this.timeLineItemWidth) {
        const listDom = this.$refs.gameListTimeline;
        listDom.scrollLeft = listDom.scrollLeft + this.timeLineItemWidth * newNum;
      }
    }
  },
  created() {},
  mounted() {
    if (this.type === "new") {
      const listDom = this.$refs.gameListTimeline;
      const startIndex = this.list.findIndex(item => item.id === this.data["new"][0].id);
      this.timeLineItemWidth = listDom.scrollWidth / this.list.length;
      listDom.scrollLeft = this.timeLineItemWidth * startIndex;
      listDom.addEventListener("scroll", throttle(this.scrollEvent, 500));
    }
  },
  updated() {},
  beforeDestroy() {},
  destroyed() {
    if (this.type === "new") {
      window.removeEventListener("scroll", throttle(this.scrollEvent, 500));
    }
  },
  methods: {
    dayjs,
    getJumpWikiUrl,
    isSelect(item) {
      const selectItemId = this.data["new"].slice(0, 3).map(it => it.id);
      return selectItemId.includes(item.id);
    },
    formatJsonStr(jsonStr) {
      return JSON.parse(jsonStr);
    },
    handleChangeType(index) {
      this.activeType = this.type[index];
    },
    openVideoPlay(video) {
      this.videoPlayVisible = true;
      this.playVideo = video;
    },
    closeVideoPlay() {
      this.videoPlayVisible = false;
      this.playVideo = "";
    },
    // 获取更多新游
    async getNewGameList() {
      try {
        if (this.new_page_no > this.new_page_total) {
          return;
        }
        const { code, data, meta } = await apiGameList({
          page_no: this.new_page_no,
          limit: 20,
          type: "new"
        });
        if (code === 0) {
          this.newGameList = this.newGameList.concat(data);
          this.new_page_total = meta.pagination.page_total;
          this.new_page_no += 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取更多即将上线
    async getFutureGameList() {
      try {
        if (this.future_page_no > this.future_page_total) {
          return;
        }
        const { code, data, meta } = await apiGameList({
          page_no: this.future_page_no,
          limit: 20,
          type: "future"
        });
        if (code === 0) {
          this.futureGameList = this.futureGameList.concat(data);
          this.future_page_total = meta.pagination.page_total;
          this.future_page_no += 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
    scrollEvent(e) {
      e.stopPropagation();
      const scrollLeft = e.target.scrollLeft;
      this.timeLineScrollLeft = scrollLeft;
      this.isInitTimelineData = false;
      if (scrollLeft <= 0) {
        this.getFutureGameList();
      } else if (scrollLeft >= e.target.scrollWidth - e.target.clientWidth - 20) {
        this.getNewGameList();
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .video-player {
  background: transparent;
  box-shadow: none;
  .el-dialog__header {
    .el-dialog__headerbtn {
      top: 10px;
      right: 5px;
      .el-icon-close {
        font-size: 24px;
        color: #fff;
      }
    }
  }
}
.custom-title {
  display: flex;
  width: 375px;
  margin-bottom: 13px;
  .title-item {
    display: block;
    width: 100%;
    height: 39px;
    font-size: 13px;
    font-weight: 400;
    color: #c1c1c1;
    line-height: 39px;
    cursor: pointer;
    background: #eeeeee;
    text-align: center;
    &.active {
      color: #333333;
      background: #fff;
      border-top: 1px solid #747474;
    }
  }
}

.game-list-container {
  &.landscape {
    flex-direction: row;
    .game-item {
      margin-right: 10px;
    }
  }
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  .game-item {
    position: relative;
    flex-shrink: 0;
    width: 340px;
    height: 268px;
    border-radius: 6px;
    border: 1px solid #b9b9b9;
    overflow: hidden;
    margin-bottom: 14px;
    .swiper-container {
      width: 100%;
      height: 192px;
      --swiper-theme-color: #7175a1;
      .swiper-slide {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .video {
          width: 100%;
          height: 100%;
          position: relative;
          .wiki-icon {
            position: absolute;
            display: inline-block;
            width: 41px;
            height: 41px;
            top: 76px;
            left: 150px;
            cursor: pointer;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .custom-swiper-pagination {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
        text-align: center;
        /deep/ .swiper-pagination-bullet {
          margin-right: 4px;
        }
      }
    }
    .game-box {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 76px;
      padding: 13px 10px;
      display: flex;
      background-color: #f8f8fa;
      .game-icon {
        flex-shrink: 0;
        width: 51px;
        height: 51px;
        margin-right: 10px;
        border-radius: 4px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          transition: all 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
      .game-info {
        flex: 1;
        width: 100%;
        .game-name {
          height: 24px;
          display: flex;
          margin-bottom: 12px;
          justify-content: space-between;
          align-items: center;
          .name {
            flex: 1;
            font-size: 17px;
            font-weight: bold;
            color: #171717;
            line-height: 24px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .time {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            height: 21px;
            padding: 2px 8px;
            // line-height: 21px;
            border-radius: 3px;
            border: 1px solid #bdbdbd;
          }
        }
        .game-desc {
          height: 18px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.95);
          line-height: 18px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
.game-list-timeline {
  --gap-width: 19px;
  display: flex;
  grid-gap: var(--gap-width);
  overflow: scroll;
  padding: 7px 0px 21px 0px;
  .item {
    .top-box {
      text-align: center;
      .date {
        height: 12px;
        font-size: 12px;
        color: #333;
        font-weight: 500;
        margin-bottom: 9px;
      }
      .line {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
        &:before {
          content: "";
          width: calc(100% + var(--gap-width));
          height: 1px;
          background: #dddddd;
          position: absolute;
          left: 0;
          top: 4px;
        }
        .round {
          width: 8px;
          height: 8px;
          background: #d8d8d8;
          border-radius: 50%;
          border: 2px solid #fff;
          position: relative;
          z-index: 1;
        }
      }
    }
    .img {
      width: 63px;
      height: 63px;
      border-radius: 4px;
      overflow: hidden;
      img {
        width: 63px;
        height: 63px;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .text-box {
      margin-top: 5px;
      width: 63px;
      overflow: hidden;
      .title {
        height: 35px;
        font-size: 13px;
        font-weight: 400;
        color: #171717;
        text-align: center;
        line-height: 17px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
