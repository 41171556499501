<template>
  <module-container title="最新wiki" url="/wikis" :isMore="isMore">
    <template #icon>
      <img src="../../../assets/images/new-icon.png" alt="" />
    </template>
    <div class="list-box">
      <div class="left-btn" @click="onScroll(-1)">
        <i class="el-icon-caret-left"></i>
      </div>
      <div ref="scrollBox" class="list-content">
        <template v-for="(item, index) in list">
          <a
            v-if="index < 10"
            :key="item.id"
            :href="getJumpWikiUrl(item.alias, item.id, item.wiki_created_at != 0)"
            :target="device.desktop ? '_blank' : '_self'"
            class="item"
          >
            <div class="top-box">
              <div class="date">
                {{ item.wiki_created_at | dateFormat("MM月DD日") }}
              </div>
              <div class="line">
                <span class="round"></span>
              </div>
            </div>
            <div class="img">
              <img :src="item.icon" :alt="item.name" />
            </div>
            <div class="name">{{ item.name }}</div>
          </a>
        </template>
      </div>
      <div class="right-btn" @click="onScroll(1)">
        <i class="el-icon-caret-right"></i>
      </div>
    </div>
  </module-container>
</template>

<script>
import ModuleContainer from "./moduleContainer";
// import SvgIcon from "../../../components/SvgIcon/svgIcon";
import { getJumpWikiUrl } from "../../../plugins/util";

export default {
  name: "newWikiContainer",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isMore: {
      type: Boolean,
      default: true
    }
  },
  model: {},
  components: {
    ModuleContainer,
    // SvgIcon
  },
  data() {
    return {
      baseUrl: ""
    };
  },
  computed: {},
  methods: {
    getJumpWikiUrl,
    onScroll(type) {
      let scrollLeft = this.$refs.scrollBox.scrollLeft;
      this.$refs.scrollBox.scrollTo({
        left: scrollLeft + type * 600,
        behavior: "smooth"
      });
    }
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.list-box {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 7px 0px 21px 0px;

  .left-btn,
  .right-btn {
    width: 14px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    background: #e0e0e0;
    cursor: pointer;
    display: none;
  }

  .list-content {
    width: (63px + 19px) * 10;
    --gap-width: 19px;
    display: grid;
    grid-gap: var(--gap-width);
    grid-template-columns: repeat(10, 1fr);

    .item {
      width: 63px;
      height: 140px;
      font-size: 12px;
      color: #212121;
      line-height: 1;
      text-align: center;

      .top-box {
        text-align: center;
        .date {
          height: 12px;
          font-size: 12px;
          color: #333;
          font-weight: 500;
          margin-bottom: 8px;
        }
        .line {
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 12px;
          &:before {
            content: "";
            width: calc(100% + var(--gap-width));
            height: 1px;
            background: #dddddd;
            position: absolute;
            left: 0;
            top: 3px;
          }
          .round {
            width: 8px;
            height: 8px;
            background: #d8d8d8;
            border-radius: 50%;
            border: 2px solid #fff;
            position: relative;
            z-index: 1;
          }
        }
      }

      .img {
        width: 63px;
        height: 63px;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        height: 35px;
        font-size: 13px;
        font-weight: 400;
        color: #171717;
        line-height: 17px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
