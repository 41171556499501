<template>
  <div class="waterDiv">
    <waterfall :col="col" :data="list">
      <a
        v-for="(item, index) in list"
        :key="index"
        :class="['article-list-item']"
        :target="device.inApp ? '_blank' : '_self'"
        :href="getJumpWikiDetailUrl(item.game_alias, item.game_id, item.id)"
      >
        <div v-if="showUser && layout != 'row'" class="top-user-box">
          <img v-lazy="handleImgSize(item.user.avatar, 'mini')" alt="" />
          <div class="r-wrapper">
            <div class="username">
              {{ item.user.username }} <span class="date">{{ item.updated_at | dateFromNow }}</span>
            </div>
            <user-tag-group :user="item.user" />
            <!--        <div class="date" v-else>{{ item.updated_at | dateFromNow }}</div>-->
          </div>
        </div>
        <div :class="['flex-box', `${item.thumb.length ? 'has-img' : ''}`, `flex-${layout}`]">
          <div v-if="item.thumb.length" class="img-box">
            <template v-for="(img, index) in item.thumb.split(',')">
              <div v-if="layout == 'row' ? index < 1 : index < 3" :key="index" class="item">
                <img v-lazy="handleImgSize(img, 'list')" alt="" />
              </div>
            </template>
          </div>
          <div class="text-box">
            <div class="title"><span v-html="xss(item.title)"></span></div>
            <div class="desc" v-html="handleSummary(item.summary)"></div>
          </div>
          <div class="game-name-desc">
            <span>
              <img :src="item.game.icon" />
              <em>{{ item.game.name }}</em>
            </span>
          </div>
        </div>
        <div class="bottom-box">
          <div class="l-box">
            <span v-if="layout == 'row'" class="user">
              <img v-lazy="handleImgSize(item.user.avatar, 'mini')" alt="" />
              <span>{{ item.user.username }}</span>
            </span>
            <a v-if="showGame" class="game-name">
              <img v-lazy="handleImgSize(item.game.icon, 'mini')" alt="" />
              {{ item.game.name }}
            </a>
            <!--        <div v-if="layout != 'row'" class="view-count"><i class="iconfont icon-yanjing"/>{{ item.view_count }}</div>-->
          </div>
          <div class="data-box">
            <div>
              <img src="../../../assets/images/commend-icon.png" /><span>{{ item.comment_count }}</span>
            </div>
          </div>
        </div>
      </a>
    </waterfall>
    <div class="clickMore" @click="getMore()">{{ tipText }}</div>
  </div>
</template>

<script>
import UserTagGroup from "../../../components/UserTag/UserTagGroup";
import { apiContentTopList } from "@/api/contentApi";
import { apiIndexShe } from "@/api/newIndexApi";
import waterfall from "../../../components/waterfall";
import { getJumpWikiDetailUrl } from "../../../plugins/util";
export default {
  name: "articleWaterItem",
  mixins: [],
  props: {
    // 图片与标题布局  row, column
    layout: {
      type: String,
      default: "row"
    },
    keyword: {
      type: String,
      default: ""
    },
    showUser: {
      type: Boolean,
      default: true
    },
    showGame: {
      type: Boolean,
      default: false
    },
    isUserHome: {
      type: Boolean,
      default: false
    },
    apiStr: {
      type: String,
      default: ""
    }
  },
  model: {},
  components: { UserTagGroup, waterfall },
  data() {
    return {
      col: 2,
      pagination: {
        limit: 10,
        page_no: 0,
        page_total: 1,
        total: 0
      },
      list: [],
      activeType: "",
      order_by: "",
      tipText: "查看更多精彩"
    };
  },
  computed: {},
  methods: {
    getJumpWikiDetailUrl,
    onClick(e) {
      if (this.isUserHome) {
        console.log("2");
        e.stopPropagation();
        e.preventDefault();
        // let url = `//${this.item.game.alias}.gamekee.com/${this.item.id}.html`;
        // window.open(url);
      }
    },
    handleSummary(text) {
      let str = text;
      if (this.keyword) {
        let reg = new RegExp(`${this.keyword}`, "gim");
        str = text.replace(reg, '<span style="color:red;">' + this.keyword + "</span>");
      }
      return str;
    },
    getList() {
      let { pagination, activeType } = this;
      // 最大页数禁止翻页
      if (pagination.page_no >= pagination.page_total) {
        this.tipText = "到底啦~换个地方看看~";
        return false;
      }
      ++pagination.page_no;
      const params = {
        ...pagination,
        type: activeType,
        order_by: this.order_by
      };
      if (this.keyword) {
        params.keyword = this.keyword;
      }
      if (this.apiStr == "index") {
        apiIndexShe(params)
          .then(res => {
            if (res.code == 0) {
              if (this.isBtn) {
                this.list = [];
              }
              this.list = this.list.concat(res.data || []);
              this.pagination = res.meta.pagination;
            }
          })
          .finally(_ => {});
      } else {
        apiContentTopList(params)
          .then(res => {
            if (res.code == 0) {
              if (this.isBtn) {
                this.list = [];
              }
              this.list = this.list.concat(res.data || []);
              this.pagination = res.meta.pagination;
            }
          })
          .finally(_ => {});
      }
    },
    getMore() {
      this.isBtn = false;
      this.getList();
    }
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
#vueWaterfall {
  & > .vue-waterfall-column:nth-child(3) .article-list-item {
    margin-left: 0;
  }
}
.clickMore {
  width: 150px;
  height: 28px;
  background: #00afe8;
  border-radius: 7px;
  margin: 12px auto 0;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  line-height: 28px;
}
.article-list-item {
  width: 100%;
  margin-bottom: 12px;
  padding-bottom: 10px;
  display: block;
  border-radius: 6px;
  background: rgba(248, 249, 249, 0.66);
  .flex-box {
    .text-box {
      padding: 7px 10px 0;
    }
  }
  .top-user-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    img {
      width: 36px;
      height: 36px;
      object-fit: cover;
      border-radius: 50%;
    }

    .r-wrapper {
      padding-left: 10px;
      font-size: 12px;

      .username {
        max-width: 150px;
        color: #2f2f2f;
        font-weight: 500;
        line-height: 20px;
      }

      .date {
        color: #989898;
        font-weight: initial;
        margin-left: 5px;
      }
    }
  }

  .flex-box {
    &.flex-row {
      .text-box {
        width: 100%;
      }
      .game-name-desc {
        padding: 8px 10px 0 10px;
        span {
          display: inline-block;
          border: 1px solid #b7b7b7;
          padding: 2px;
          border-radius: 3px;
        }
        img {
          display: block;
          float: left;
          width: 17px;
          height: 17px;
          border-radius: 0.0375rem 0px 0px 0.0375rem;
        }
        em {
          display: block;
          max-width: 110px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          float: left;
          font-size: 12px;
          color: #6d6d6d;
          font-weight: 400;
          font-style: normal;
          margin-left: 6px;
          line-height: 18px;
        }
      }
      &.has-img {
        .text-box {
          padding: 0 10px;
          margin-top: 4px;
          .title {
            font-size: 14px;
            color: #333333;
            line-height: 19px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .desc {
            font-size: 13px;
            color: #999;
            line-height: 17px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-top: 6px;
          }
        }
      }

      .img-box {
        width: 100%;

        img {
          width: 100%;
          max-height: 300px;
          object-fit: cover;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
      }
    }

    &.flex-column {
      flex-direction: column;

      .img-box {
        display: flex;

        .item {
          margin-right: 14px;

          &:last-child {
            margin-right: 0;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
          }
        }

        @media screen and (max-width: 500px) {
          .item {
            width: calc((100% - 14px * 2) / 3);
            height: 90px;
          }
        }

        @media screen and (min-width: 500px) {
          .item {
            width: 140px;
            height: 90px;
            max-width: calc((100% - (14px * 2)) / 3);
          }
        }
      }
    }

    .text-box {
      cursor: pointer;

      .title {
        .ellipsis;
        width: 145px;
        font-size: 14px;
        line-height: 24px;
        color: #212121;
        span {
          display: block;
          .ellipsis;
          width: 145px;
          font-size: 14px;
          line-height: 24px;
          color: #212121;
        }
        .iconfont {
          font-size: 16px;
          margin-right: 5px;
          color: #e74957;
        }
      }

      .desc {
        font-size: 12px;
        color: #868686;
        line-height: 18px;
        overflow: hidden;
        max-height: 36px;
      }
    }
  }

  .bottom-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 11px;
    color: #666;
    font-size: 12px;
    padding: 0 10px;
    .l-box {
      display: flex;
      align-items: center;

      .game-name {
        font-size: 12px;
        color: #666;
        background: @border-basic;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        height: 22px;
        margin-right: 14px;

        img {
          width: 16px;
          height: 16px;
          border-radius: 2px;
          margin-right: 6px;
        }
      }

      .user {
        display: flex;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 7px;
        }
      }
    }

    .iconfont {
      margin-right: 4px;
      font-size: 14px;
      color: #000;
    }

    div {
      display: flex;
      align-items: center;
    }

    .data-box {
      display: flex;
      align-items: center;

      > div {
        display: flex;
        align-items: center;
        img {
          width: 14px;
          height: 14px;
        }
        span {
          margin-top: 2px;
          margin-left: 2px;
        }
      }

      > div + div {
        margin-left: 12px;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
