<template>
  <module-container title="热门wiki" url="/wikis" :isMore="isMore">
    <template #icon>
      <img src="../../../assets/images/hot-icon.png" alt="" />
    </template>
    <div :class="['item-group', classBase + 'item-group', device.orientation]">
      <template v-for="(item, index) in list">
        <a
          v-if="index < 8"
          :key="item.id"
          :href="getJumpWikiUrl(item.alias, item.game_id, true)"
          :target="device.desktop ? '_blank' : '_self'"
          class="item"
        >
          <img v-lazy="handleImgSize(item.cover)" :alt="item.game_name" />
          <div class="text-box">
            <div class="title">{{ item.game_name }}</div>
            <div class="info"><img src="../../../assets/images/fire-icon1.png" />{{ item.hot }}</div>
          </div>
        </a>
      </template>
    </div>
  </module-container>
</template>

<script>
// import SvgIcon from "../../../components/SvgIcon/svgIcon";
import ModuleContainer from "./moduleContainer";
import { getJumpWikiUrl } from "../../../plugins/util";

export default {
  name: "hotWikiContainer",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isMore: {
      type: Boolean,
      default: true
    }
  },
  model: {},
  components: {
    ModuleContainer
    // SvgIcon
  },
  data() {
    return {
      baseUrl: ""
    };
  },
  computed: {},
  methods: {
    getJumpWikiUrl
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.item-group {
  display: flex;
  flex-wrap: wrap;

  &.wap-item-group {
    width: 108 * 4 + 9px * 4;
    box-sizing: content-box;
    height: 398px;

    .item {
      width: 108px;
      height: 182px;
      margin-right: 9px;

      img {
        height: 140px;
        border-radius: 0.0375rem;
      }
    }
  }
  &.landscape {
    width: 100%;
    flex-wrap: nowrap !important;
    height: auto !important;
    overflow-x: auto !important;
    .item {
      flex: 0 0 auto;
      margin-right: 10px;
      &:nth-child(4n) {
        margin-right: 10px;
      }
    }
  }

  .item {
    width: 210px;
    height: 98px + 48px;
    background: #fff;
    margin-right: calc((100% - (210px * 4)) / 3);
    overflow: hidden;
    margin-bottom: 17px;
    &:nth-child(4n) {
      margin-right: 0;
    }
    img {
      width: 100%;
      height: 98px;
      display: block;
      object-fit: cover;
    }

    .text-box {
      height: 42px;
      padding: 6px 2px;
      .title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #171717;
        font-size: 13px;
        text-align: center;
      }

      .info {
        color: #999;
        font-size: 12px;
        display: flex;
        justify-content: center;
        margin-top: 4px;
        line-height: 14px;
        img {
          display: block;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
</style>
