<template>
  <div :class="['index-page', classBase + 'index-page']">
    <!-- banner -->
    <banner-container :list="moduleList.bannerList" />
    <!-- 热门wiki -->
    <hot-wiki-container :list="moduleList.hotWikiList" :isMore="true" />
    <!-- 最新wiki -->
    <new-wiki-container :list="moduleList.newWiki" :isMore="true" />
    <!-- 每日游戏 -->
    <new-game-container :data="moduleList.indexGame" type="every_day" />
    <!-- 最近新游 -->
    <new-game-container :data="moduleList.indexGame" type="new" />
    <!-- 游戏推荐 -->
    <game-recommend :list="moduleList.contentRecommend"></game-recommend>
    <!-- 游戏情报 -->
    <div class="newsContainer">
      <news-container :list="moduleList.newsList" :isDetail="true" :isSearch="false" />
    </div>
    <!-- 特别期待, 情报公开 -->
    <new-game-container :data="moduleList.indexGame" :type="['follow', 'foretell']" />
    <!-- 游戏视频 -->
    <!-- <div class="newsContainer">
        <video-container :list="moduleList.videoList" />
      </div> -->

    <!-- k站推荐 -->
    <!-- <game-wiki-list :list="moduleList.gameRecommend" /> -->
    <!-- 玩家社区 -->
    <div class="wiki-list">
      <div class="wiki-list-content">
        <div class="sheqTit"><img src="@/assets/images/sheq-icon.png" /><span>玩家社区</span></div>
        <div v-if="!device.desktop" class="list-nav-box">
          <div class="list-nav">
            <div
              v-for="(item, index) in typeActive"
              :key="index"
              :class="activeType === item.type ? 'active' : ''"
              class="list-nav-a"
              @click="handleBtn(item.type)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <!-- <notice-list-group :list="topList"/> -->
        <wap-model-container class="list-content" :isActive="true">
          <article-water-item :activeType="activeType" ref="secList" :apiStr="'index'" />
        </wap-model-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// components
import BannerContainer from "./components/bannerContainer";
import HotWikiContainer from "./components/hotWikiContainer";
import NewWikiContainer from "./components/newWikiContainer";
import NewGameContainer from "./components/newGameContainer";
import GameRecommend from "./components/gameRecommend";
import NewsContainer from "./components/newsContainer";
// import videoContainer from "./components/videoContainer";
import WapModelContainer from "@/views/wiki/components/wapModelContainer";
import ArticleWaterItem from "@/views/www/components/articleWaterItem";
// import GameWikiList from "@/views/www/components/gameWikiList";
// import NoticeListGroup from '@/views/wiki/components/noticeListGroup';
// import SvgIcon from "../../components/SvgIcon/svgIcon";
// api
// import { apiContentList } from "@/api/contentApi";
import { apiWwwIndex } from "../../api/wwwApi";
// plugins
// import eventBus from "../../plugins/eventBus";
// mixinss
import mergeAsyncDataMixin from "@/mixins/mergeAsyncDataMixin";

function handleModuleList(data) {
  const moduleList = {
    indexGame: [],
    videoList: [], // 视频列表
    bannerList: [], // 轮播模块
    adList: [], // 广告模块
    manualList: [], // wiki手册
    hotWikiList: [], // 热门wiki
    newsList: [], // 情报
    newWiki: [], // 最新wiki
    wikiLib: [], // wiki库
    gameRecommend: [] // K站推荐
  };
  if (data) {
    moduleList.wikiLib = data.wiki_lib;
    moduleList.newWiki = data.new_wiki;
    moduleList.newsList = data.news_list;
    moduleList.indexGame = data.index_game;
    moduleList.videoList = data.video_list;
    moduleList.gameRecommend = data.recommend_game;
    moduleList.contentRecommend = data.content_recommend ? data.content_recommend.slice(0, 4) : [];
    data.module_list.forEach(item => {
      if (item.module.type == 1) {
        moduleList.bannerList = item.list;
      } else if (item.module.type == 11) {
        moduleList.adList = item.list;
      } else if (item.module.type == 13) {
        moduleList.manualList = item.list;
      } else if (item.module.type == 10) {
        moduleList.hotWikiList = item.list;
      }
    });
  }

  return moduleList;
}
export default {
  name: "index",
  mixins: [mergeAsyncDataMixin],
  props: {},
  model: {},
  components: {
    BannerContainer,
    HotWikiContainer,
    NewWikiContainer,
    NewGameContainer,
    GameRecommend,
    NewsContainer,
    // videoContainer,
    // SvgIcon,
    WapModelContainer,
    // NoticeListGroup,
    ArticleWaterItem
  },
  data() {
    return {
      moduleList: handleModuleList(),
      activeType: "4", // 默认帖子类型
      topList: [],
      typeActive: [
        {
          type: "4",
          name: this.$t("posts")
        },
        {
          type: "15",
          name: this.$t("strategy")
        },
        {
          type: "",
          name: this.$t("all")
        }
      ]
    };
  },
  async asyncData({ ssrContext, $apis }) {
    try {
      console.log("asyncData start");
      // TDK设置
      // ssrContext.title = "wiki 主站";
      // ssrContext.keywords = "wiki 主站 keywords";
      // ssrContext.description = "wiki 主站 description";
      // 数据预取
      const { data } = await $apis.apiWwwIndex();
      const moduleList = handleModuleList(data);
      console.log("asyncData end");
      return {
        moduleList
      };
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    ...mapGetters(["loginUid", "loginUser", "redPoint", "gameInfo"])
  },
  methods: {
    getData() {
      apiWwwIndex().then(({ data }) => {
        this.moduleList = handleModuleList(data);
        // this.$nextTick(() => {
        //   this.$refs.gameWiki.gameList = data.recommend_game[0].game_list;
        //   this.$refs.gameWiki.activeType = data.recommend_game[0].id;
        //   // console.log(this.moduleList.gameRecommend);
        // });
      });
    },
    // 切换分类，清空滚动状态
    handleBtn(type) {
      this.activeType = type;
      this.$nextTick(() => {
        this.$refs.secList.isBtn = true;
        this.$refs.secList.pagination = {
          limit: 10,
          page_no: 0,
          page_total: 1,
          total: 0
        };
        this.$refs.secList.activeType = this.activeType;
        this.$refs.secList.getList();
      });
    }
    // getTopList() {
    //   this.loading = true;
    //   const params = {
    //     limit: 3,
    //     type: "2"
    //   };
    //   apiContentList(params)
    //     .then(res => {
    //       if (res.code == 0) {
    //         this.topList = res.data || [];
    //       }
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // }
  },
  watch: {},
  created() {},
  beforeMount() {
    this.getData();
  },
  mounted() {
    // this.getTopList();
    this.$refs.secList.getList();
  },
  activated() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.index-page {
  width: 100%;
}

.newsContainer {
  padding: 0 17px;
  background: #fff;
}
.wiki-list {
  display: flex;
  align-items: flex-start;
  position: relative;
  background: #fff;
  .wiki-list-content {
    width: 100%;
    min-height: 13.33333rem;
    border-radius: 0.16rem;
    margin-bottom: 0.53333rem;
    .list-content {
      margin-top: 18px;
    }
  }
  .sheqTit {
    padding: 0 17px;
    display: flex;
    font-size: 14px;
    margin-top: 14px;
    img {
      display: block;
      width: 17px;
      height: 17px;
      margin-right: 5px;
    }
    span {
      font-size: 14px;
      color: #333;
      line-height: 17px;
      font-weight: 600;
    }
  }
  .list-nav {
    display: flex;
    align-items: stretch;
    padding: 0 17px;
    margin-top: 14px;
    border-bottom: 1px solid rgba(234, 234, 234, 0.8);
    .list-nav-a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999;
      font-size: 13px;
      border-bottom: 2px solid transparent;
      box-sizing: border-box;
      padding-bottom: 10px;
      & + .list-nav-a {
        margin-left: 25px;
      }

      &.active {
        color: #333;
        font-weight: 600;
        position: relative;

        &:after {
          content: "";
          width: 20px;
          height: 2px;
          border-radius: 2px;
          background: #00afe8;
          position: absolute;
          bottom: -2px;
          left: 50%;
          transform: translateX(-10px);
        }
      }
    }
  }
}
</style>
